.Section6{
    width: 100%;
    height: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
    /* padding: 50px; */
    margin-top: 150px;
    gap: 30px;
}
.img-section6{
    width: 50%;
    height: 400px;
}
.img-section6 img{
    width: 100%;
    height: 400px;
    border-radius: 15px;
}
.right-section6{
    width: 40%;
    height: 600px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 40px;
    margin-top: 80px;
}
.right-section6 h1{
    font-size: 45px;
    width: 100%;    
}
.right-section6 p{
    font-size: 18px;
    width: 100%;    
}



@media screen and (max-width: 800px) {
    .Section6{
        height: 800px;
        flex-direction: column;
        margin-top: 150px;
    }
    .img-section6{
        width: 90%;
        height: 300px;
    }
    .img-section6 img{
        width: 100%;
        height: 300px;
        border-radius: 15px;
    }
    .right-section6{
        width: 90%;
        height: 300px;
    }
}



@media screen and (max-width: 426px) {
    .Section6{
        height: 800px;
        flex-direction: column;
        margin-top: 80px;
    }
    .img-section6{
        width: 90%;
        height: 300px;
    }
    .img-section6 img{
        width: 100%;
        height: 300px;
        border-radius: 15px;
    }
    .right-section6{
        width: 90%;
        height: 300px;
    }
}
