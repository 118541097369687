.Section7 {
    margin-top: 150px; /* Espacement depuis le haut */
    width: 100%;
    height: 600px;
    position: relative;
    z-index: -2;
}

/* .horizontalSection {
flex: 1;
 Permet à la section de prendre l'espace disponible horizontalement
}
.orangeSection {
    flex: 1;
     Permet à la section de prendre l'espace disponible horizontalement
} */
.skewed-horizontal {
transform: skewX(0deg); /* Inclinaison horizontale */
}

.skewed-vertical {
transform: skewY(-10deg); /* Inclinaison verticale */
}

.scroll-horizontal {
    width: 100%;
    height: 150px;
    overflow: hidden;
    background-color: rgb(0, 85, 255);
    color: aliceblue;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 80px;
    
}
.scroll-verticale {
    width: 100%;
    height: 150px;
    overflow: hidden;
    background-color: orange;    
    color: aliceblue;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 80px;

}


@keyframes ticker-horizontal {
    0% {
      transform: translateX(-100%); /* Départ à droite */
    }
    100% {
      transform: translateX(100%); /* Arrivée à gauche */
    }
  }
  
  @keyframes ticker-vertical {
    0% {
      transform: translateX(100%); /* Départ à gauche */
    }
    100% {
      transform: translateX(-100%); /* Arrivée à droite */
    }
  }
  
  .scroll-content-horizontal {
    animation: ticker-horizontal 10s infinite linear;
    display: inline-block;
    width: 100%;
    white-space: nowrap;
  }
  
  .scroll-content-vertical {
    animation: ticker-vertical 10s infinite linear;
    display: inline-block;
    width: 100%;
    white-space: nowrap;
  }
  
