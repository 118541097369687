.Section4{
    width: 100%;
    height: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
    /* padding: 100px; */
    margin-top: 150px;
    gap: 70px;
}
.img-section4{
    width: 45%;
    height: 400px;
}
.img-section4 img{
    width: 100%;
    height: 400px;
    border-radius: 15px;
}
.right-section4{
    width: 40%;
    height: 600px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 40px;
}
.right-section4 h1{
    font-size: 45px;
    width: 95%;    
}
.right-section4 p{
    font-size: 17.5px;
    width: 95%;    
}


    @media screen and (max-width: 800px) {
        .Section4{
            flex-direction: column;
            margin-top: 150px;
            height: 900px;
            gap: 40px;
        }
        .img-section4{
            width: 90%;
            height: 400px;
        }
        .img-section4 img{
            width: 100%;
            height: 400px;
            border-radius: 15px;
        }
        .right-section4{
            width: 90%;
            height: 300px;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            gap: 20px;
        }
        .right-section4 h1{
            font-size: 45px;
            width: 95%;    
        }
        .right-section4 p{
            font-size: 17.5px;
            width: 95%;    
        }
    }
    


@media screen and (max-width: 426px) {
    .Section4{
        flex-direction: column;
        margin-top: 150px;
        height: 700px;
        gap: 0px;
    }
    .img-section4{
        width: 90%;
        height: 400px;
    }
    .img-section4 img{
        width: 100%;
        height: 400px;
        border-radius: 15px;
    }
    .right-section4{
        width: 90%;
        height: 600px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        gap: 20px;
    }
    .right-section4 h1{
        font-size: 45px;
        width: 95%;    
    }
    .right-section4 p{
        font-size: 17.5px;
        width: 95%;    
    }
}


/* _____________________________________________________________________________________ */


