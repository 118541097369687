.Section3{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
    gap: 50px;
}

.titre-section3 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* width: 46%; */
    text-align: center;
    font-size: 20px;
}
.img-section3{
    width: 300px;
    height: 300px;
}
.img-section3 img{
    width: 100%;
    height: 300px;
}