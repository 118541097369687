.Navbar {
    display: flex;
    justify-content: center;
    align-items:center;
    /* gap: 150px; */
    width: 100%;
    height: 150px;
    background-color: rgb(243, 243, 243);
    position: fixed;
    top: 0px;
}
.Navbar.scrolled {
    background-color: white;
    transition: background-color 1s ease;
}
.titre-nav{
    margin-top: 50px;
    width: 20%;
    font-size: 50px;
}
.nav-links {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 150px;
    height: 150px;

}

.lien-nav {
    display: flex;
    gap: 40px;
    text-decoration: none;
    margin-top: 20px;
}
.lien-nav button {
    text-decoration: none;
    color: black;
    font-size: 20px;
    background-color: transparent;
    border: 1px solid transparent;
    cursor: pointer;
    position: relative; /* Ajoutez une position relative pour que le pseudo-élément ::after fonctionne correctement *\ */
}
.lien-nav button::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: -13px;/* Ajustez cette valeur pour positionner la ligne sous le texte  */
    width: 0; /* Définissez la largeur initiale de la ligne à 0  */
    height: 3px; /* Ajustez la taille de la ligne  */
    background-color: black; /* Couleur de la ligne  */
    transition: width 0.5s ease;  /* Ajoutez une transition pour l'animation  */
} 
.lien-nav button:hover::after {
    width: 100%; /* Changez la largeur de la ligne au survol  */
}

.Btn-Nav{
    width: 200px;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-color: rgb(236, 1, 119);
    border: 1px solid rgb(236, 1, 119);
    border-radius: 10px;
    font-size: 20px;
    color: white;
    margin-top: 30px;
    cursor: pointer;
}

.Btn-lan{
    width: 150px; 
}
.dropdown{ 
    width: 150px;
}
.dropbtn{
    cursor:pointer;
    background-color: transparent;
    width: 150px;
    height: 30px;
    border: 1px solid black;
    border-radius: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 50px;
    /* margin-top: 20px; */
}
.dropdown-content{
    cursor:pointer;
    background-color: transparent;
    width: 150px;
    height: 30px;
    border: 1px solid black;
    border-radius: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 50px;
}

.nav-links li {
    margin-right: 20px;
}


.burger {
    display: none;
}



  /* Responsive styles */
  @media (min-width: 1201px) and (max-width: 1400px) {
    .Navbar {
        display: flex;
        justify-content: center;
        align-items:center;
        gap: 20px;
        width: 100%;
    }
    .titre-nav{
        margin-top: 40px;
        width: 20%;
        font-size: 35px;
    }
    .nav-links {
        width: 70%;
        gap: 70px;
    }
    .dropbtn{
        margin-top: 30px;
    }
  }
  @media (min-width: 801px) and (max-width: 1200px) {
    .Navbar {
        display: flex;
        justify-content: center;
        align-items:center;
        gap: 20px;
        width: 100%;
    }
    .titre-nav{
        margin-top: 40px;
        width: 10%;
        font-size: 35px;
    }
    .nav-links {
        width: 80%;
        gap: 20px;
    }
    .dropbtn{
        margin-top: 25px;
        width: 100px;
    }
    .Btn-lan{
        width: 100px; 
    }
    .dropbtn{
        cursor:pointer;
        background-color: transparent;
        width: 100px;
        height: 30px;
        border: 1px solid black;
        border-radius: 15px;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 20px;
    }
    
    
  }


  /* ___________________________________________________________________ */

@media (max-width: 800px) {
    .Navbar {
        display: flex;
        justify-content: center;
        align-items:center;
        gap: 200px;
        width: 100%;
        height: 100px;
        background-color: rgb(243, 243, 243);
        position: fixed;
        top: 0px;
    }
    .titre-nav{
        margin-top: 25px;
        width: 20%;
        font-size: 35px;
    }
    .nav-links {
        position: absolute;
        left: 50;
        height: 100vh;
        top: 10vh;
        background-color: #333333f5;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        transform: translateX(100%);
        transition: transform 0.5s ease-in;
        gap: 100px;
    }
    
    .nav-links li {
        opacity: 0;
    }
    .lien-nav {
        margin-top: 20px;
        color: white;
    }
    .lien-nav button{
        margin-top: 20px;
        color: white;
    }
    .dropbtn{
        color: white;
        
    }
    .Btn-Nav{
        width: 200px;
        height: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        font-size: 20px;
        margin-top: 0px;
    }
    .burger {
        display: block;
        cursor: pointer;
        color: black;
        width: 30px;
        height: 30px;
    }

    .burger div {
        width: 55px;
        height: 5px;
        background-color: black;
        margin: 5px;
        transition: all 0.3s ease;
    }
    
    .nav-active {
        transform: translateX(0);
    }

    @keyframes navLinkFade {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }

    .nav-links li {
        animation: navLinkFade 0.5s ease forwards 0.5s;
    }
    .toggle .line1 {
        transform: rotate(-45deg) translate(-5px, 6px);
        /* background-color: black; */
    }

    
    .toggle .line2 {
        opacity: 0;
    }
    
    .toggle .line3 {
        transform: rotate(45deg) translate(-5px, -6px);
    }
    
    .nav-links a:focus,
    .nav-links a:hover {
        color: #fff;
    }
}
@media (min-width: 300px) and(max-width: 425px) {
    .Navbar {
        display: flex;
        justify-content: center;
        align-items:center;
        gap: 100px;
       
    }
}




/* _____________________________________________________________________________________ */


