.Section2{
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
    margin-top: 70px;
}

.cercle-blanc{
    width: 300px;
    height: 300px;
    background-color: white;
    border-radius: 50%;
    position: absolute;
    z-index: -1;
    left: 220px;
    top: 800px;
}
.titre-section2{
    margin-top: 35px;
    font-size: 30px;
    width: 60%;
    line-height: 70px;
    margin-right: 190px;
    
}
.Section2 svg{
    margin-right: 500px;
    /* margin-bottom: 15px; */
    margin-top: 0px;
}
.para-section2-en{
    font-size: 20px;
    width: 61%;
    margin-top: 30px;
    margin-right: 190px;
}
.para-section2-fr{
    font-size: 20px;
    width: 80%;
    margin-top: 30px;
    margin-left: 110px;
}

@media screen and (max-width: 1200px) {
    .cercle-blanc{
        left: 400px;
        top: 1400px;
    }
    .titre-section2{
        font-size: 30px;
        width: 80%;
        margin-left: 100px;
    }
    .Section2 svg{
        margin-right: 500px;
    }
}
@media screen and (max-width: 800px) {
    .cercle-blanc{
        left: 130px;
        top: 1350px;
    }
    .titre-section2{
        font-size: 30px;
        width: 70%;
        margin-right: 100px;
    }
    .Section2 svg{
        margin-right: 00px;
    }
    .para-section2-en{
        font-size: 20px;
        width: 61%;
        margin-top: 30px;
        margin-right: 190px;
    }
    .para-section2-fr{
        width: 90%;
        margin-top: 30px;
        margin-left: 50px;
    }
}

@media screen and (max-width: 426px) {
    .Section2 svg{
        margin: 20px;
    }
    .cercle-blanc{
        left: 100px;
        top: 1200px;
        width: 250px;
        height: 250px;
    }
    .titre-section2{
        font-size: 30px;
        width: 100%;
        margin-right: 0px;
        
    }
    .Section2 svg{
        margin-right: 50px;
    }
    .para-section2-en{
        font-size: 20px;
        width: 61%;
        margin-top: 30px;
        margin-right: 190px;
    }
    .para-section2-fr{
        width: 90%;
        margin-top: 30px;
        margin-left: 50px;
    }
}


/* _____________________________________________________________________________________ */


