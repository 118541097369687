body{
  margin: 0;
  padding: 0;
  background-color: rgb(243, 243, 243);
}
@media (max-width: 800px) {
  html, body {
    width: 100%;
    overflow-x: hidden;
    margin: 0;
    padding: 0;
  }
}