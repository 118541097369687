.Footer{
    background-color: black;
    color: white;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
    gap: 50px;
    height: 500px;
}

.left-footer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 50%;
    gap: 50px;
}
.left-footer h1{
    font-size: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80%;
}
.left-footer button{
    width: 50%;
    height: 50px;
    font-size: 20px;
    background-color: rgb(236, 1, 119);
    border: 1px solid rgb(236, 1, 119);
    border-radius: 50px;
    color: white;
    cursor: pointer;
}
.right-footer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items:flex-start;
    width: 50%;
    /* margin-left: 180px;  */
    font-size: 22px;  
}
.right-footer h2{
    margin: 0px;
    margin-bottom: 50px;
    font-size: 60px;
}
.right-footer a{
    text-decoration: none;
    color: white;
    margin-bottom: 20px;
}


@media screen and (max-width: 1200px) {
    .Footer{
        /* flex-direction: column; */
        height: 500px;
        margin-top: 150px;
        grid-area: 70px; 
    }
    .left-footer{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 50%;
        gap: 50px;
    }
    .left-footer h1{
        font-size: 40px;
        width: 100%;
    }
    .left-footer button{
        width: 70%;
        height: 100px;
        font-size: 20px;
    }
    .right-footer{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items:center;
        width: 40%;
        margin-left: 0px; 
        font-size: 22px;  
    }
    .right-footer h2{
        margin: 0px;
        margin-bottom: 50px;
        font-size: 40px;
    }
    .right-footer a{
        text-decoration: none;
        color: white;
        margin-bottom: 20px;
    }
}



@media screen and (max-width: 768px) {
    .Footer{
        flex-direction: column;
        height: 900px;
        margin-top: 150px;
        grid-area: 70px;
    }
    .left-footer{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 90%;
        gap: 50px;
    }
    .left-footer h1{
        font-size: 50px;
        width: 100%;
    }
    .left-footer button{
        width: 70%;
        height: 100px;
        font-size: 30px;
    }
    .right-footer{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items:center;
        width: 90%;
        margin-left: 0px; 
        font-size: 22px;  
    }
    .right-footer h2{
        margin: 0px;
        margin-bottom: 50px;
        font-size: 50px;
    }
    .right-footer a{
        text-decoration: none;
        color: white;
        margin-bottom: 20px;
    }
    
}
