.Section1{
    margin-top: 150px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.Section1-left{
    width: 40%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.Section1-left h1{
    font-size: 60px;
    width: 90%;
}
.Section1-left svg{
    position: relative;
    right: 90px;
    z-index: -1;
}
#layer_1{
    width: 300px;
    margin: 0 auto;
}

.svgPath{
    stroke: rgb(255, 199, 15);/* strokeが線の色 */
    stroke-width: 20px;/* stroke-widthは線幅 */
    stroke-dasharray:2500px;/* stroke-dasharrayは破線との間隔 */
    stroke-dashoffset: 2500px;
    animation: line 4s 0.3s forwards linear;
}

    @keyframes line{
        0%{stroke-dashoffset: 2500px;}
        100%{stroke-dashoffset: 0;}
    }

.Btn-Section1{
    background-color: rgb(236, 1, 119);
    width: 220px;
    height: 80px;
    cursor: pointer;
    border: 1px solid rgb(236, 1, 119);
    border-radius: 40px;
    text-align: center;
    font-size: 23px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    margin-right: 330px;
    margin-top: 40px;
}
.Section1-right{
    width: 55%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.Section1-right img{
    width: 100%;
    height: 600px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 15px;
}
@media screen and (max-width: 1200px) {
    .Section1{
        width: 100% ;
        gap: 20px;
        flex-direction: column-reverse;
    }
    .Section1-left{
        gap: 20px;
        width: 100%;
    }
    .Section1-left svg{
        right: 20px;
        width: 90%;
    }
    #layer_1{
        width: 150px;
        margin: 0 auto;
    }
    .Btn-Section1{
        margin-right: 30px;
    }
    .Section1-right{
        height: 500px;
        width: 90%;
    }
    .Section1-right img{
        height: 500px;
        width: 100%;
    }
    .Btn-Section1{
        width: 300px;
    }
    
    }
    

@media screen and (max-width: 800px) {
.Section1{
    width: 100% ;
    gap: 20px;
    flex-direction: column-reverse;
    margin-top: 100px;
}
.Section1-left{
    gap: 20px;
    width: 100%;
}
.Section1-left svg{
    right: 20px;
    width: 90%;
}
#layer_1{
    width: 150px;
    margin: 0 auto;
}
.Btn-Section1{
    margin-right: 30px;
}
.Section1-right{
    height: 500px;
    width: 90%;
}
.Section1-right img{
    height: 400px;
    width: 100%;
}
.Btn-Section1{
    width: 300px;
}

}

@media screen and (max-width: 426px) {
    .Section1{
    width: 100% ;
    gap: 50px;
    flex-direction: column-reverse;
    margin-top: 110px;
}
.Section1-left{
    gap: 20px;
    width: 100%;
}
.Section1-left h1{
    font-size: 40px;
    width: 90%;
}
.Section1-left svg{
    right: 0px;
    width: 200px;
}
#layer_1{
    width: 150px;
    margin: 0 auto;
}
.Btn-Section1{
    margin-right: 0px;
    margin-left: 20px;
}
.Section1-right{
    height: 400px;
}
.Section1-right img{
    height: 400px;
}

}


/* _____________________________________________________________________________________ */


