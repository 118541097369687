.Section5{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 80px;
    width: 100%;
    margin-top: 150px;
}
.left-section5{
    width: 40%;
    height: 600px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 30px;
}
.left-section5 h1{
    width: 80%;
    font-size: 50px;
}
.left-section5 p{
    width: 80%;
    font-size: 22px;
}
.img-section5{
    width: 50%;
    height: 600px;
}
.img-section5 img{
    width: 100%;
    height: 600px;
    border-radius: 15px;
}



@media screen and (max-width: 800px) {
    .Section5{
        flex-direction: column-reverse;
        gap: 0px;
        width: 100%;
        margin-top: 100px;
    }
    .img-section5{
        width: 90%;
        height: 600px;
    }
    .img-section5 img{
        width: 100%;
        height: 500px;
        border-radius: 15px;
    }
    .left-section5{
        width: 90%;
        height: 300px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 30px;
    }
    .left-section5 h1{
        width: 95%;
        font-size: 40px;
    }
    .left-section5 p{
        width: 90%;
        font-size: 22px;
    }
}



@media screen and (max-width: 426px) {
    .Section5{
    flex-direction: column-reverse;
    gap: 70px;
    width: 100%;
    margin-top: 150px;
}
.img-section5{
    width: 90%;
    height: 400px;
}
.img-section5 img{
    width: 100%;
    height: 400px;
    border-radius: 15px;
}
.left-section5{
    width: 90%;
    height: 500px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0px;
}
.left-section5 h1{
    width: 95%;
    font-size: 40px;
}
.left-section5 p{
    width: 90%;
    font-size: 22px;
}

}
