.Section8{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    gap: 30px;
    z-index: -5;
}
.top-Section8{
    display: flex;
    width: 100%;
    justify-content: center ;
    /* position: relative;
    right: 350px; */
}
.top-Section8{
    font-size: 28px;
    z-index: -1;

}
.plus{
    cursor: pointer;
    font-size: 30px;
}
.Section8 p{
    font-size: 20px;
}

.bottom-Section8{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 600px;
    gap: 50px;
}
.left-bottom-Section8{
    width: 40%;
    height: 300px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    margin-top: 50px;
}
.titre-left-bottom-Section8{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
}
.left-bottom-Section8 h1{
    font-size: 30px;
}
.img-bottom-Section8{
    width: 50%;
    height: 500px;
}

.img-bottom-Section8 img{
    width: 100%;
    height: 500px;
    border-radius: 15px;
}
.hr{
    width: 100%;
    height: 1px;
    background-color: black;
}




@media screen and (max-width: 800px) {
    .Section8{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        gap: 50px;
        z-index: -5;
    }
    .bottom-Section8{
        flex-direction: column-reverse;
        width: 100%;
        height: 600px;
        gap: 50px;
    }
    .img-bottom-Section8{
        width: 90%;
        height: 300px;
    }
    
    .img-bottom-Section8 img{
        width: 100%;
        height: 300px;
        border-radius: 15px;
    }
    .left-bottom-Section8{
        width: 90%;
        height: 300px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 20px;
        margin-top: 50px;
    }
    .hr{
        display: none;
    }
}



@media screen and (max-width: 426px) {
    .Section8{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        gap: 50px;
        z-index: -5;
    }
    .bottom-Section8{
        flex-direction: column-reverse;
        width: 100%;
        height: 600px;
        gap: 50px;
    }
    .img-bottom-Section8{
        width: 90%;
        height: 300px;
    }
    
    .img-bottom-Section8 img{
        width: 100%;
        height: 300px;
        border-radius: 15px;
    }
    .left-bottom-Section8{
        width: 90%;
        height: 300px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 20px;
        margin-top: 50px;
    }
    .hr{
        display: none;
    }
}
